body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgba(204, 204, 204, 0.5);
  height: 100%;
}

html,
#root {
  height: 100%;
}

.wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
}

.container {
  max-width: 80%;
  min-width: 65%;
  padding: 1rem;
  border-radius: 5px;
  background-color: white;
  margin-top: 40px;
  margin-bottom: 40px;
}

.container .form-container__image {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.container .form-wrapper {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

label {
  display: flex;
  align-items: center;
  width: 40%;
}

label.required::before {
  content: "*";
  padding-right: 5px;
  color: red;
}

.container .form-wrapper__input {
  flex: 1;
}

.form-wrapper__input--small {
  width: 11%;
}

.container .form-wrapper__input input {
  width: 100%;
  margin: 0px;
  box-sizing: border-box;
  font-size: 0.9em;
  padding: 0.4em;
}

.container .form-wrapper__input input.hasError {
  border-color: red;
}

.container .form-wrapper__input select {
  width: 90%;
}

.container .form-wrapper__input p {
  margin: 0px;
  font-size: 10px;
  color: red;
  padding: 5px;
}

button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  color: rgb(255, 255, 255);
  padding: 1rem;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
}

button span {
  margin-left: 10px;
}

button.submitting {
  opacity: 0.6;
  padding: 0.5rem;
  cursor: not-allowed;
}

button:focus {
  outline-color: transparent;
  outline-style: none;
}

.error {
  padding: 1rem;
  color: white;
  background-color: tomato;
  border: 1px solid red;
  border-radius: 5px;
  margin-bottom: 0.8rem;
}

.success {
  text-align: center;
}

form h2 {
  margin: 0px 0px 10px;
}

form h4 {
  text-decoration: underline;
  margin: 15px 0px 10px;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 500px;
    min-width: 500px;
  }
}
